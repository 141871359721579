import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getVoucherIsPosting, getVoucherPostSuccess, getVouchersError } from '@selectors/vouchers';
import { CloseModal, HandleSubmit } from 'src/types/shared/Functions';
import dayjs from 'dayjs';
import { createVoucher } from '@actions/vouchers';
import { removeFieldError } from 'lib/src/redux/actions/fieldErrors';

const initialState: FormState = {
    title: '',
    internalName: '',
    validFromDate: new Date(),
    validFromTime: dayjs().format('HH:mm'),
    validToDate: new Date(),
    validToTime: dayjs().format('HH:mm'),
    usesPerIndividual: 1,
    isUnlimited: false,
    shortDescription: '',
    description1: '',
    terms: '',

    thumbnailS3Key: '',
    mainImageS3Key: '',
    storeIds: [],
    isHighlighted: false,
    customBarcode: '',
    publishOnDate: null,
    publishOnTime: '',
};

export default function useCreateVoucher(closeModal: CloseModal): UseCreateVoucherReturn {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formState, handleChange] = useForm(initialState);

    const handleSubmit = useCallback(() => {
        const {
            validFromDate,
            validFromTime,
            validToDate,
            validToTime,
            isUnlimited,
            usesPerIndividual,
            publishOnDate,
            publishOnTime,
        } = formState;
        if (!validFromDate || !validFromTime) {
            return;
        }

        dispatch(
            createVoucher({
                ...formState,
                usesPerIndividual: isUnlimited ? null : usesPerIndividual,
                validFrom: dayjs(validFromDate).format(`YYYY-MM-DDT${validFromTime}`),
                validTo: dayjs(validToDate).format(`YYYY-MM-DDT${validToTime}`),
                publishOn:
                    publishOnDate && publishOnTime
                        ? dayjs(publishOnDate).format(`YYYY-MM-DDT${publishOnTime}`)
                        : null,
            }),
        );
        history.push('/vouchers');
    }, [dispatch, history, formState]);

    const isPosting = useSelector(getVoucherIsPosting);
    const error = useSelector(getVouchersError);
    const postSuccess = useSelector(getVoucherPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    useEffect(() => {
        dispatch(removeFieldError('validFrom'));
        dispatch(removeFieldError('validTo'));
    }, [dispatch, formState]);

    return {
        formState,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    };
}

interface FormState {
    title: string;
    internalName: string;
    validFromDate: Date;
    validFromTime: string;
    validToDate: Date;
    validToTime: string;
    usesPerIndividual: number;
    isUnlimited: boolean;
    shortDescription: string;
    description1: string;
    terms: string;
    thumbnailS3Key: string | null;
    mainImageS3Key: string | null;
    storeIds: number[];
    isHighlighted: boolean;
    customBarcode: string | null;
    publishOnDate: Date | null;
    publishOnTime: string;
}

interface UseCreateVoucherReturn {
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    handleSubmit: HandleSubmit;
    isPosting: boolean;
    error: string | null;
}
